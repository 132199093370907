import SectionTitle from "pages/common/SectionTitle"
import ContainerMain from "pages/common/ContainerMain"
import SubTitle from "pages/common/SubTitle"
import Content from "pages/common/Content"
import "./WhatWeOfferTemplate.scss"

export default function WhatWeOfferTemplate({
  title,
  description,
  nColClass,
  content,
}) {
  return (
    <ContainerMain>
      <div className="what-we-offer-template">
        <SectionTitle title={title} />
        <SubTitle nColClass={nColClass} children={description}></SubTitle>
        <Content>{content}</Content>
      </div>
    </ContainerMain>
  )
}
